import { useMemo } from 'react'
import parse from 'html-react-parser'
import { Meter } from 'grommet'
import styled from 'styled-components'

import {
  Box,
  Button,
  ColorProp,
  duration as durationFormatter,
  Icon,
  IconButton,
  IconName,
  resolveColor,
  themeColor,
  ThemeType,
  useNotify,
  useTheme
} from '@cutover/react-ui'
import {
  PauseRunbookModal,
  ResumeRunbookModal,
  StartRunbookModal,
  UpdateRunModal
} from 'main/components/runbook/modals/run-modals'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import {
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  CurrentRunbookVersionModel,
  RunbookViewModel,
  TaskModel
} from 'main/data-access'
import { formatDate } from 'Shared/Helpers/DateService'
import { useAccountTaskTypes } from 'main/services/api/data-providers/account/account-data'

const CONTROL_ICONS: { [stage: string]: IconName } = {
  planning: 'play-arrow',
  active: 'pause',
  paused: 'play-arrow',
  complete: 'check-solid',
  cancelled: 'close',
  skipped: 'skipped',
  abandoned: 'abandoned'
}

const COMMS_ICONS: { [comms: string]: IconName } = {
  off: 'volume-mute',
  on: 'volume-high',
  test: 'volume-low'
}

const COMMS_ICON_COLORS: { [comms: string]: ColorProp } = {
  off: 'text-light',
  on: 'success',
  test: 'warning'
}

const CHILD_RUNBOOK_TIMER_COLORS: { [status: string]: ColorProp } = {
  late: 'warning',
  early: 'success',
  unscheduled: 'text-light',
  scheduled: 'primary'
}

export type RunModalAction = 'show-start' | 'show-pause' | 'show-resume'

export const RunbookControl = () => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook' })
  const theme = useTheme()
  const runbookType = ActiveRunbookModel.useRunbookType()
  const { closeRightPanel } = useSetActiveRightPanelState()

  const openModal = RunbookViewModel.useAction('modal:open')
  const closeModal = RunbookViewModel.useAction('modal:close')
  const { active: activeModal } = RunbookViewModel.useGet('modal')
  const notify = useNotify()

  const {
    id: runbookId,
    template_type: templateType,
    linked_runbook_details: linkedRunbookDetails,
    timezone
  } = ActiveRunbookModel.useGet()
  const run = ActiveRunModel.useGet()

  const {
    stage: runbookVersionStage,
    id: runbookVersionId,
    auto_start,
    completed_tasks_count,
    tasks_count,
    start_scheduled: startScheduled
  } = ActiveRunbookVersionModel.useGet()

  const { start_scheduled, timing_mode } = CurrentRunbookVersionModel.useGet()
  const progressValue = completed_tasks_count ? (completed_tasks_count / tasks_count) * 100 : 0

  const linkedParentStatus = linkedRunbookDetails && linkedRunbookDetails.current_status

  const stageInheritedFromParent =
    linkedParentStatus?.actioned_by === 'task' && linkedParentStatus?.linked_resource !== 'active'
      ? linkedParentStatus.linked_resource
      : undefined

  const stage = stageInheritedFromParent ?? runbookVersionStage

  const tasks = TaskModel.useGetAll() ?? []

  const isTemplate = templateType === 'default'

  const canCreateRun = ActiveRunModel.useCan('create')
  const canPauseRun = ActiveRunModel.useCan('pause')
  const canResumeRun = ActiveRunModel.useCan('resume')
  const canUpdateRun = ActiveRunModel.useCan('update')

  const comms = run?.comms
  const canEditComms = canUpdateRun && stage !== 'complete' && stage !== 'cancelled'

  const isChildRunbook = Object.keys(linkedRunbookDetails || {}).length !== 0

  const { taskTypes } = useAccountTaskTypes()

  const agenticTasksPresent = () => {
    const taskTypeIds = tasks?.map(task => task.task_type_id)
    const aiEnabledTaskTypeIds = taskTypes
      ?.filter(taskType => taskType.ai_features_enabled)
      .map(taskType => taskType.id)

    return aiEnabledTaskTypeIds?.some(id => taskTypeIds?.includes(id))
  }

  const agenticTasksCorrectlyConfigured = () => {
    const taskTypeIds = tasks?.map(task => task.task_type_id)
    const aiEnabledTaskTypeIds = taskTypes
      ?.filter(taskType => taskType.ai_features_enabled)
      .map(taskType => taskType.id)

    const countMatches = (taskTypeIds: number[], aiEnabledTaskTypeIds: number[]) =>
      taskTypeIds.filter(value => aiEnabledTaskTypeIds.includes(value)).length

    const matchCount = countMatches(taskTypeIds, aiEnabledTaskTypeIds || [])
    return (matchCount || 0) % 2 === 0 // agentic tasks must be in pairs
  }

  const handleIconClick = () => {
    if (agenticTasksPresent() && !agenticTasksCorrectlyConfigured()) {
      return notify.error('Agentic tasks must be in pairs', { title: 'Unable to start runbook' })
    }

    closeRightPanel()
    switch (stage) {
      case 'planning':
        return openModal({ type: 'run-start' })
      case 'active':
        return openModal({ type: 'run-pause' })
      case 'paused':
        return openModal({ type: 'run-resume' })
    }
  }

  const handleCommsClick = () => {
    openModal({ type: 'run-update' })
  }

  const getParentForecastScheduleDiff = useMemo(() => {
    if (!linkedRunbookDetails?.linked_start_forecast || !startScheduled) {
      return 0
    }
    return linkedRunbookDetails.linked_start_forecast - startScheduled
  }, [linkedRunbookDetails, startScheduled])

  const getLinkedChildTimerDetails = useMemo(() => {
    if (!startScheduled) {
      return { status: 'unscheduled', text: t('childTimer.unscheduled'), plainText: t('childTimer.unscheduled') }
    }
    const scheduledStart = formatDate(new Date(startScheduled * 1000), 'do MMM yyyy HH:mm')
    const forecastScheduleDiff = getParentForecastScheduleDiff

    if (forecastScheduleDiff === 0) {
      return {
        status: 'scheduled',
        text: t('childTimer.scheduled', { scheduledStart: scheduledStart }),
        plainText: t('childTimer.scheduled', { scheduledStart: scheduledStart })
      }
    }
    const forecastText = durationFormatter(forecastScheduleDiff, 3)
    const status = forecastScheduleDiff > 0 ? 'late' : 'early'
    const forecastDisplay = parse(
      t('childTimer.inProgress', {
        scheduledStart: scheduledStart,
        forecastText: forecastText,
        status: status
      })
    )
    const forecastDisplayPlain = t('childTimer.inProgressPlain', {
      scheduledStart: scheduledStart,
      forecastText: forecastText,
      status: status
    })

    return { status, text: forecastDisplay, plainText: forecastDisplayPlain }
  }, [startScheduled, getParentForecastScheduleDiff])

  const linkedChildTimerDetails = getLinkedChildTimerDetails

  const runModal = useMemo(() => {
    const isParentRunbook = tasks.some(task => task.linked_resource && task.linked_resource.id)

    switch (activeModal?.type) {
      case 'run-start':
        const isLiveRunInParentRunbook = isChildRunbook && linkedRunbookDetails?.linked_resource?.run_type === 'live'

        return (
          <StartRunbookModal
            onClose={closeModal}
            runbookVersionId={runbookVersionId}
            runbookId={runbookId}
            templateType={templateType}
            hasParentRunbook={isChildRunbook}
            isLiveRunInParentRunbook={isLiveRunInParentRunbook}
            isScheduled={timing_mode === 'scheduled'}
            autoStart={auto_start}
            startScheduled={start_scheduled}
            timezone={null} // TODO: timezone is handled as a TimezoneType in modal but is a string here
          />
        )
      case 'run-pause':
        return (
          <>
            {run && (
              <PauseRunbookModal
                onClose={closeModal}
                runbookId={runbookId}
                runbookVersionId={runbookVersionId}
                runId={run.id}
                templateType={templateType}
                isParentRunbook={isParentRunbook}
                runType={run.run_type}
                isRunCommsOff={run.comms === 'off'}
              />
            )}
          </>
        )
      case 'run-resume':
        return (
          <>
            {run && (
              <ResumeRunbookModal
                onClose={closeModal}
                runId={run.id}
                runbookVersionId={runbookVersionId}
                runbookId={runbookId}
                templateType={templateType}
                isParentRunbook={isParentRunbook}
                runType={run.run_type}
                isRunCommsOff={run.comms === 'off'}
              />
            )}
          </>
        )
      case 'run-update':
        return run && <UpdateRunModal onClose={closeModal} />
      default:
        return null
    }
  }, [
    activeModal?.type,
    closeModal,
    runbookId,
    runbookVersionId,
    templateType,
    linkedRunbookDetails,
    runbookType,
    timezone,
    tasks
  ])

  const showControl = () => {
    if (runbookVersionId && !isTemplate && !runbookType?.incident) {
      if (
        (stage === 'planning' && canCreateRun && tasks_count > 0 && !linkedParentStatus?.linked_resource) ||
        (stage === 'active' && canPauseRun) ||
        (stage === 'paused' && canResumeRun) ||
        stage === 'cancelled' ||
        stage === 'complete' ||
        (stage === 'skipped' && linkedParentStatus?.linked_resource === 'skipped') ||
        (stage === 'abandoned' && linkedParentStatus?.linked_resource === 'abandoned')
      ) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <Box css="position: relative">
        {stage === 'planning' ? (
          <DashedCircle />
        ) : (
          <Meter
            type="circle"
            background="bg-4"
            size="52px"
            css={{ minWidth: '52px', maxWidth: '52px' }}
            thickness="5px"
            values={[{ value: progressValue, color: run?.run_type === 'rehearsal' ? 'text-disabled' : 'primary' }]}
          />
        )}
        {stage && showControl() ? (
          <Box
            direction="row"
            align="center"
            css="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"
          >
            {stage === 'cancelled' ? (
              <Icon icon="close" size="32px" color="bg-4" aria-hidden="true" aria-label="icon-cancelled" />
            ) : stage === linkedParentStatus?.linked_resource && linkedParentStatus?.actioned_by !== 'resource' ? (
              <Icon
                icon={CONTROL_ICONS[stage]}
                size="32px"
                color="bg-4"
                aria-hidden="true"
                aria-label={`icon-${CONTROL_ICONS[stage]}`}
              />
            ) : (
              <Button
                data-testid={`runbook-control-${stage}-button`}
                plain
                icon={CONTROL_ICONS[stage]}
                a11yTitle={stage === 'active' ? t('pauseRun') : stage === 'complete' ? t('runComplete') : t('startRun')}
                onClick={handleIconClick}
                className={stage}
                css={`
                  border-radius: 50%;
                  padding: 5px;
                  cursor: pointer;
                  svg {
                    fill: ${run?.run_type === 'live' && stage === 'complete'
                      ? resolveColor('primary', theme)
                      : resolveColor('text-light', theme)};
                  }
                  &.complete {
                    cursor: default;
                  }
                  &:not(.complete):hover {
                    svg {
                      fill: ${resolveColor('text', theme)};
                    }
                  }
                `}
              />
            )}
          </Box>
        ) : null}
        {comms && !linkedParentStatus?.linked_resource && (
          <Box css="position: absolute; right: -3px; bottom: -3px;">
            <IconButton
              icon={COMMS_ICONS[comms]}
              size="small"
              label={comms === 'off' ? t('commsOff') : comms === 'test' ? t('commsTest') : t('commsOn')}
              onClick={handleCommsClick}
              disabled={!canEditComms}
              data-testid="runbook-comms-button"
              css={`
                opacity: 1 !important;
                background: ${resolveColor('bg', theme)};
                svg {
                  fill: ${resolveColor(COMMS_ICON_COLORS[comms], theme)};
                }
                &:hover {
                  background: ${resolveColor('bg-1', theme)};
                  svg {
                    fill: ${resolveColor(COMMS_ICON_COLORS[comms], theme)};
                  }
                }
              `}
            />
          </Box>
        )}
        {isChildRunbook && !run && <ChildRunbookTimer details={linkedChildTimerDetails} theme={theme} />}
      </Box>
      {runModal}
    </>
  )
}

const ChildRunbookTimer = ({
  details,
  theme
}: {
  details: { status: string; text: string | JSX.Element | JSX.Element[]; plainText: string }
  theme: ThemeType
}) => (
  <Box css="position: absolute; left: -3px; bottom: -3px;">
    <IconButton
      icon="time"
      size="small"
      label={details.text}
      aria-label={details.plainText}
      a11yTitle={details.plainText}
      data-testid="child-runbook-timer"
      css={`
        opacity: 1 !important;
        background: ${resolveColor('bg', theme)};
        svg {
          fill: ${resolveColor(CHILD_RUNBOOK_TIMER_COLORS[details.status], theme)};
        }
        &:hover {
          background: ${resolveColor('bg', theme)};
          cursor: default;
          svg {
            fill: ${resolveColor(CHILD_RUNBOOK_TIMER_COLORS[details.status], theme)};
          }
        }
      `}
    />
  </Box>
)

const DashedCircle = styled(Box)`
  width: 52px;
  height: 52px;
  border: 4px dashed ${themeColor('bg-3')};
  border-radius: 50%;
`
